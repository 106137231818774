import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import {
  ButtonClose,
  ContainerMultiplataform,
  ContentAlert,
  ContentText,
  Line,
  StyledDescription,
  TextAlert,
  TextMultiplataform,
  TextRemaining,
  Title,
  TitleAlert,
  Video,
  VideoOverlay,
} from "./styles";

import Header from "../../components/header";
import PortfolioYMK from "../../components/portfolio";
import YMKnumbers from "../../components/ymkNumbers";
import Footer from "../../components/footer";

import urblar from "../../assets/logotipo_urblar.webp";
import estoque_imovel from "../../assets/estoqueimoveis.webp";
import ymk_corp from "../../assets/logo_ymk_corp.webp";
import singular from "../../assets/singular.webp";
import realstate from "../../assets/logo_realestate.webp";
import ymk_land from "../../assets/logo_ymk_land.webp";
import ymk_capital from "../../assets/ymk capital.webp";
import rai from "../../assets/logo_rai_branco.webp";
import investimov from "../../assets/logo_investimov.webp";
import ymkagro from "../../assets/ymk agro.webp";
import credimov from "../../assets/logo-credimov.webp";
import video from "../../assets/video-home.mp4";

import { Box, Modal } from "@mui/material";

const Home = () => {
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

  const { t } = useTranslation();

  const company = [
    {
      id: 1,
      title: `${t("translations.home.imoveis")}`,
      logo: [
        { image: estoque_imovel, link: "https://www.estoqueimovel.com.br" },
        { image: singular, link: "https://singularrealty.com.br" },

        { image: ymk_corp, link: "https://www.ymkcorp.com.br" },
        { image: realstate, link: "https://realestate4me.com.br" },
        { image: urblar, link: "https://urblar.com.br" },
      ],
      description: [
        `${t("translations.home.estoque")}`,
        `${t("translations.home.singular")}`,
        `${t("translations.home.ymk_corp")}`,
        `${t("translations.home.realstate")}`,
        `${t("translations.home.urblar")}`,
      ],
    },
    {
      id: 2,
      title: `${t("translations.home.corporate")}`,
      logo: [
        { image: ymk_land, link: "https://www.ymkland.com.br" },
        { image: ymk_capital, link: "#" },
        { image: rai, link: "https://rainvestimentos.com.br" },
      ],
      description: [
        `${t("translations.home.ymk_land")}`,
        `${t("translations.home.ymk_capital")}`,
        `${t("translations.home.rai")}`,
      ],
    },
    {
      id: 3,
      title: `${t("translations.home.private")}`,
      logo: [{ image: investimov, link: "#" }],
      description: [<Title>{t("translations.home.investimov")}</Title>],
      subtitle: [
        <StyledDescription>
          {t("translations.home.investimov_market")}
        </StyledDescription>,
        <StyledDescription>
          {t("translations.home.investimov_prioritarias")}
        </StyledDescription>,
        <StyledDescription>
          {t("translations.home.investimov_blockdeals")}
        </StyledDescription>,
      ],
    },
    {
      id: 4,
      title: `${t("translations.home.credito")}`,
      logo: [{ image: credimov, link: "https://credimov.com.br" }],
      description: [<Title>{t("translations.home.credimov")}</Title>],
      subtitle: [
        <StyledDescription style={{ fontWeight: "500", fontSize: 18 }}>
          {t("translations.home.credimov_subtitle")}
        </StyledDescription>,
        <StyledDescription>
          {t("translations.home.credimov_financiamento")}
        </StyledDescription>,
        <StyledDescription>
          {t("translations.home.credimov_cgi")}
        </StyledDescription>,
        <StyledDescription>
          {t("translations.home.credimov_consorcio")}
        </StyledDescription>,
        <StyledDescription>
          {t("translations.home.credimov_contemplado")}
        </StyledDescription>,
      ],
    },
    {
      id: 5,
      title: `${t("translations.home.agro")}`,
      logo: [{ image: ymkagro, link: "#" }],
      description: [<Title>{t("translations.home.ymk_agro")}</Title>],
      subtitle: [
        <StyledDescription>
          {t("translations.home.ymk_agro_subtitle")}
        </StyledDescription>,
      ],
    },
  ];

  const theme = useTheme();

  const [shouldHeaderHaveBackground, setShouldHeaderHaveBackground] =
    useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const threshold = 350;

    setShouldHeaderHaveBackground(scrollPosition > threshold);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "70%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 651px)": {
      width: "90%",
    },
    "@media(max-width: 515px)": {
      height: "90%",
      width: "100%",
    },
    "@media(max-width: 360px)": {
      height: "90%",
      width: "100%",
      p: 2,
    },
  };

  return (
    <main>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <ButtonClose onClick={handleClose}>X</ButtonClose>
          <ContentAlert>
            <TitleAlert>
              Atenção para algumas dicas de proteção contra golpes
            </TitleAlert>
            <Line />
            <TextAlert>
              <strong>
                O Grupo YMK não solicita pagamentos, pix ou transferências para
                NENHUMA pessoa física a título de antecipação, reserva, sinal
                etc.{" "}
              </strong>
              Qualquer depósito, pagamento ou transferência será de acordo com o
              contrato, para as devidas partes (CNPJ) ou vendedores, mencionadas
              no mesmo.
            </TextAlert>
            <TextAlert>
              <strong>
                Cuidado com os contratos falsos e "benefícios super exclusivos".
              </strong>{" "}
              Criminosos se apropriam de discursos falsos e com muitas promessas
              para atrair clientes para seus golpes.
            </TextAlert>
            <TextAlert>
              <strong>
                Todos os e-mails dos colaboradores do grupo YMK estão vinculados
                ao domínio @ymk.com.br. Em caso de dúvida entre em contato com
                nossos canais:{" "}
                <strong style={{ color: "#FED136" }}>contato@ymk.com.br</strong>{" "}
                ou ligue
              </strong>{" "}
              (11) 3995-7808.
            </TextAlert>
            <TextAlert>
              O Grupo YMK não divulga ou compartilha os seus dados{" "}
              <strong>de acordo com as normas da LGPD.</strong>
            </TextAlert>
          </ContentAlert>
        </Box>
      </Modal>
      <Video autoPlay loop muted>
        <source src={video} />
      </Video>

      <VideoOverlay />
      <Header
        backgroundColor={
          shouldHeaderHaveBackground ? theme.colors.primary : "transparent"
        }
      />
      <ContainerMultiplataform>
        <ContentText>
          <TextMultiplataform>
            {t("translations.home.multiplataforma")}
          </TextMultiplataform>
          <TextRemaining>
            {t("translations.home.multiplataforma_negocios")}
          </TextRemaining>
        </ContentText>
      </ContainerMultiplataform>
      <YMKnumbers />
      <PortfolioYMK companies={company} />
      <Footer />
    </main>
  );
};

export default Home;
